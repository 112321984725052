import React from 'react'
import Layout from 'elements/Frame/Layout'
import Title from 'elements/Typography/Title'
import { Text } from '@chakra-ui/core'

export default function MarketplacePage () {
  return (
    <Layout>
      <Title label="Marketplace">Marketplace - Coming soon!</Title>
      <Text> In the near future you will be able to browse community modules and packages you can install to enhance your services.</Text>
    </Layout>
  )
}
